import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import exceptions from 'src/enums/messages/exception';
import { PartialJournalState } from 'src/enums/partial-journal-state';
import { v4 as uuidv4 } from 'uuid';
import {
  CollectionField,
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  FormAutocomplete,
  FormContext,
  FormDecimalField2,
  FormFileField,
  FormLocalDateTimeField,
  FormPanel,
  FormSelect,
  FormTextField,
  UserContext,
} from '@eas/common-web';
import { PartialJournalBasicFields } from '@modules/partial-journals/fields/overview';
import { useValidationSchema } from '@modules/partial-journals/partial-journal-schema';
import { usePersons } from '@modules/person/person-api';
import { useTenants } from '@modules/tenant/tenant-api';
import { JournalEntryField } from '@composite/journal-entry-collection/journal-entry-field';
import { Me, PrimaryJournal } from '@models';
import {
  EvidenceApiUrl,
  JournalTypeEnum,
  PartialJournalCreatePermissions,
  PartialJournalListPermissions,
  Permission,
  PrimaryJournalState,
} from '@enums';
import { personLabelMapper } from '@utils/label-mapper';
import { useUserAssignments } from '@utils/use-user-assignments';
import {
  useConstructionTypes,
  usePrimaryJournalState,
} from '../primary-journal-api';
import { usePartialJournalTableColumns } from '../primary-journal-columns';
import {
  JOURNAL_ENTRY,
  PARTIAL_SOURCE,
} from '../primary-journal-hooked-sources';

export function OverviewFields() {
  const { hookedSources } = useContext(EvidenceContext);
  const {
    source: { data },
    isLocked,
    mode,
  } = useContext<DetailHandle<PrimaryJournal>>(DetailContext);
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const { setFieldValue } = useContext(FormContext);

  const { isPrimaryJournalAssigned } = useUserAssignments();

  const states = usePrimaryJournalState();
  const persons = usePersons();
  const tenants = useTenants();
  const constructionTypes = useConstructionTypes();

  const hasPartialJournalPermission = PartialJournalListPermissions.some(
    (permission) => hasPermission(permission)
  );
  const hasJournalEntryPermission =
    hasPermission(Permission.JournalEntry.JOURNAL_ENTRY_LIST) &&
    isPrimaryJournalAssigned(data?.id);

  const partialJournalSource = hookedSources[PARTIAL_SOURCE]?.source;
  const journalEntrySource = hookedSources[JOURNAL_ENTRY]?.source;

  const showNameField = mode === DetailMode.NEW;

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__PRIMARY_JOURAL__DETAIL__PANEL_TITLE"
            defaultMessage="Základné údaje hlavného denníka"
          />
        }
      >
        {showNameField && (
          <FormTextField
            name="name"
            label={
              <FormattedMessage
                id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__NAME"
                defaultMessage="Názov denníku"
              />
            }
            required
            disabled
          />
        )}
        <FormTextField
          name="construction.name"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL___CONSTRUCTION_NAME"
              defaultMessage="Názov stavby"
            />
          }
          notifyChange={(value) => setFieldValue('name', value)}
          required
        />
        <FormTextField
          name="construction.siteId"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL___CONSTRUCTION_ID"
              defaultMessage="Číslo stavby"
            />
          }
          required
        />
        <FormSelect
          name="construction.type"
          source={constructionTypes}
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__STATE"
              defaultMessage="Typ stavby"
            />
          }
          valueIsId
          required
          disabled={mode !== DetailMode.NEW}
        />
        {/*    <FormAutocomplete
           name="investor"
           source={ dodavatel / vlastník}
           labelMapper={personLabelMapper}
           label={
             <FormattedMessage
               id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__SUPPLIER"
               defaultMessage="Zhotovitel"
             />
           }
         /> */}

        <FormAutocomplete
          name="supervisor"
          source={persons}
          labelMapper={personLabelMapper}
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__SUPERVISOR"
              defaultMessage="Technický dozor vlastníka / PaI"
            />
          }
        />
        <FormAutocomplete
          name="planner"
          source={persons}
          labelMapper={personLabelMapper}
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__PLANNER"
              defaultMessage="Zodpovedný projektant"
            />
          }
        />
        {/*    <FormAutocomplete
           name="supplier"
           source={ dodavatel / vlastník}
           labelMapper={personLabelMapper}
           label={
             <FormattedMessage
               id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__SUPPLIER"
               defaultMessage="Zhotovitel"
             />
           }
         /> */}

        <FormAutocomplete
          name="leader"
          source={persons} // žřejmě upravit na osoby dodavatela/vlastník
          labelMapper={personLabelMapper}
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__LEADER"
              defaultMessage="Hlavný stavbyvedúci"
            />
          }
          required
        />
        {/*    <FormAutocomplete
           name=""
           source={ dodavatel / vlastník}
           labelMapper={personLabelMapper}
           label={
             <FormattedMessage
               id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__SUPPLIER"
               defaultMessage="Projektový manažér / manažér prevádzky"
             />
           }
         /> */}
        {/*    <FormAutocomplete
           name=""
           source={ dodavatel / vlastník}
           labelMapper={personLabelMapper}
           label={
             <FormattedMessage
               id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__SUPPLIER"
               defaultMessage="Hlavný vedúci prác / vedúci skupiny"
             />
           }
         /> */}
        <FormTextField
          name="team"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__TEAM"
              defaultMessage="Tím zhotoviteľa"
            />
          }
          required
        />
        <FormSelect
          name="state"
          source={states}
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__STATE"
              defaultMessage="Stav hlavného denníka"
            />
          }
          valueIsId
          disabled
        />

        {data?.finalReport && (
          <FormFileField
            name="finalReport"
            label={
              <FormattedMessage
                id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__FINAL_REPORT"
                defaultMessage="Záverečná správa"
              />
            }
            disabled
          />
        )}
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ESD__PRIMARY_JOURAL__DETAIL__PANEL_TITLE__ADDRESS"
            defaultMessage="Lokalita"
          />
        }
        expandable
      >
        <FormTextField
          name="address.municipality"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__MUNICIPALITY"
              defaultMessage="Obec"
            />
          }
          required
        />
        <FormTextField
          name="address.region"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__REGION"
              defaultMessage="Región"
            />
          }
          required
        />
        <FormDecimalField2
          name="address.coordinates.latitude"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__LATITUDE"
              defaultMessage="Zemepisná šírka"
            />
          }
          fixedDecimalDigits={false}
          decimalDigits={10}
          decimalSeparator="."
        />
        <FormDecimalField2
          name="address.coordinates.longitude"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__LOGITUDE"
              defaultMessage="Zemepisná dĺžka"
            />
          }
          fixedDecimalDigits={false}
          decimalDigits={10}
          decimalSeparator="."
        />
      </FormPanel>

      {hasPartialJournalPermission && (
        <CollectionField
          source={partialJournalSource}
          title={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__PANEL_TITLE__PARTIAL_JOURNALS"
              defaultMessage="Dielčie denníky"
            />
          }
          useColumns={usePartialJournalTableColumns}
          exceptions={exceptions}
          disabled={isLocked || data?.state === PrimaryJournalState.REOPENED}
          createAction={{
            getUrl: () => EvidenceApiUrl.PARTIAL_JOURNAL,
            Dialog: PartialJournalBasicFields,
            hide: !PartialJournalCreatePermissions.every((permission) =>
              hasPermission(permission)
            ),
            dialogWidth: 700,
            initValues: () => {
              return {
                id: uuidv4(),
                journalType: JournalTypeEnum.PARTIAL,
                state: PartialJournalState.CREATED,
                name: data?.name || data?.construction?.name,
                parent: {
                  id: data?.id,
                  journalType: JournalTypeEnum.PRIMARY,
                  name: data?.name,
                  construction: data?.construction,
                  leader: data?.leader,
                  team: data?.team,
                  state: data?.state,
                },
              };
            },
            useSchema: useValidationSchema,
          }}
          readAction={{
            getUrl: (collectionId) =>
              `${EvidenceApiUrl.PARTIAL_JOURNAL}/${collectionId}`,
          }}
          updateAction={{
            getUrl: (_, collectionId) =>
              `${EvidenceApiUrl.PARTIAL_JOURNAL}/${collectionId}`,
            // Dialog: PartialJournalBasicFields,
            hide: true,
          }}
          deleteAction={{
            getUrl: (_, collectionId) =>
              `${EvidenceApiUrl.PARTIAL_JOURNAL}/${collectionId}`,
            hide: !hasPermission(
              Permission.PartialJournal.PARTIAL_JOURNAL_DELETE
            ),
          }}
          assignAction={{
            getUrl: (_, collectionId) =>
              `${EvidenceApiUrl.PARTIAL_JOURNAL}/${collectionId}`,
            hide: true,
          }}
          unassignAction={{
            getUrl: (_, collectionId) =>
              `${EvidenceApiUrl.PARTIAL_JOURNAL}/${collectionId}`,
            hide: true,
          }}
        />
      )}

      {hasJournalEntryPermission && data?.id && (
        <JournalEntryField
          source={journalEntrySource}
          currentRef={{
            id: data?.id,
            journalType: JournalTypeEnum.PRIMARY,
          }}
          disabled={isLocked || data?.state === PrimaryJournalState.REOPENED}
        />
      )}

      <FormPanel
        label={
          <FormattedMessage
            id="ESD__PRIMARY_JOURAL__DETAIL__PANEL_TITLE__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
        expandable
      >
        <FormLocalDateTimeField
          name="lastEntry"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__LAST_ENTRY"
              defaultMessage="Posledný denný záznam denníka"
            />
          }
          disabled
        />
        <FormLocalDateTimeField
          name="reopened"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__REOPENED"
              defaultMessage="Znovuotvorenie denníka"
            />
          }
          disabled
        />
        <FormTextField
          name="reopenedReason"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__REOPENED_REASON"
              defaultMessage="Dôvod znovuotvorenia denníka"
            />
          }
          disabled
        />
        <FormLocalDateTimeField
          name="closed"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__CLOSED"
              defaultMessage="Uzavretie denníka"
            />
          }
          disabled
        />
        <FormLocalDateTimeField
          name="cancelled"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__CANCELLED"
              defaultMessage="Zrušenie denníka"
            />
          }
          disabled
        />
        <FormTextField
          name="cancelledReason"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__CANCELLED_REASON"
              defaultMessage="Dôvod zrušenia denníka"
            />
          }
          disabled
        />
        <FormLocalDateTimeField
          name="created"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__CREATED"
              defaultMessage="Vytvorenie denníka"
            />
          }
          disabled
        />
        <FormTextField
          name="createdBy.name"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__CREATED_BY"
              defaultMessage="Autor vytvorenia denníka"
            />
          }
          disabled
        />
        <FormLocalDateTimeField
          name="updated"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__UPDATED"
              defaultMessage="Posledná úprava denníka"
            />
          }
          disabled
        />
        <FormTextField
          name="updatedBy.name"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__UPDATED_BY"
              defaultMessage="Autor poslednej úpravy denníka"
            />
          }
          disabled
        />
        <FormAutocomplete
          name="tenant"
          source={tenants}
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__TENANT"
              defaultMessage="Vlastník stavebného denníka"
            />
          }
          disabled
        />
      </FormPanel>
    </>
  );
}
